import React, {useEffect, useState} from 'react'
import Header from "../../components/h5/Header";
import WeChatMask from "../../components/h5/WeChatMask";
import {JsBridge} from "../../utils/JsBridge";
import {isWechat} from "../../utils/EnvUtils";
import {getWebBaseHost} from "../../restful/request";
import IconShare from '@/images/h5-common/icon_share.webp';
import IconBack from '@/images/h5-common/icon_back.webp';
import DowloadAppBanner from "../../components/h5/DowloadAppBanner";

const PriceUp = () => {
    const [showWeixinMask, setShowWeixinMask] = useState<boolean>(false);

    useEffect(() => {
        document.title = '调价通知'
        JsBridge.hideClientNavbar(
            JSON.stringify({visible: false, statusBarColor: '#FDD648'})
        )
    }, []);

    function onClose() {
        // window.history.go(-1);
        JsBridge.closeWebview(JSON.stringify({ sourceFrom: 'h5' }));
    }

    function onShareUrl() {
        if (isWechat()) {
            setShowWeixinMask(true);
        } else {
            JsBridge.shareUrl(
                JSON.stringify({
                    title: "涨价倒计时！！！",
                    text: '奇鹿App，即将涨价，赶紧抢购！',
                    url: `${getWebBaseHost()}/price_up`,
                    // thumbImg:`${directoryBasicModel?.imageUrl??""}`
                })
            );
        }
    }

    function renderHeaderLeftBtn() {
        return (
            <img
                onClick={onClose}
                src={IconBack}
                alt=''
                className={`w-[36px] h-[36px] ${JsBridge.isInApp() ? "" : "invisible"}`}
            />
        );
    }

    function renderHeaderRightBtn() {
        return (
            <img
                onClick={onShareUrl}
                src={IconShare}
                alt=''
                className='w-[36px] h-[36px]'
            />
        );
    }

    return (
        <div className='flex items-center justify-center'>
            <div className={`relative max-w-[500px] w-full overflow-y-auto pb-[${JsBridge.isInApp()?'0':'80'}px]`}>
                <Header
                    title='调价通知'
                    // headerBgColor="#FDD648"
                    // className="bg-[#FDD648] text-white"
                    leftBtn={renderHeaderLeftBtn()}
                    rightBtn={renderHeaderRightBtn()}
                    className="absolute top-0 left-0 right-0 z-10"
                />
                <img
                    alt=''
                    src="https://media.deerbook.cn/banner/price_up_detail2.webp"
                    className='w-full object-cover'
                />
            </div>
            {JsBridge.isInApp()?"":<DowloadAppBanner />}
            <WeChatMask
                isVisible={showWeixinMask}
                closeMask={() => setShowWeixinMask(false)}
                maskType={1}
            />
        </div>
    )
}

export default React.memo(PriceUp)
