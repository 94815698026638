import IconQilu from '@/images/h5-common/logo_qilu.webp';
import React from 'react';

// 定义Props接口
interface DownloadAppBannerProps {
  alignBottom?: boolean;
}

function DowloadAppBanner({alignBottom=true}: DownloadAppBannerProps) {
  return (
    <div className='fixed top-0 bottom-0 right-0 left-0 pointer-events-none z-[1000]'>
      <div className='relative w-full h-full'>
        <div className={`absolute ${alignBottom?'bottom-0 ':'top-0'} left-0 right-0 pointer-events-auto`}>
          <div className='flex w-full justify-center bg-white'>
            <div className='flex flex-row h-[80px] w-full max-w-[800px] shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] items-center justify-between pl-[16px] pr-[16px]'>
              <div className='flex flex-row items-center'>
                <img src={IconQilu} alt='' className='w-[55px] h-[55px]'/>
                <div className='flex flex-col ml-[10px]'>
                  <div className='text-[#313131] text-[14px] font-semibold whitespace-nowrap mb-[3px]'>
                    奇鹿
                  </div>
                  <div className='text-[#666666] text-[13px] font-normal whitespace-nowrap'>
                    下载免费阅读<span className='text-[#FFAA1B]'>3w+绘本</span>
                  </div>
                </div>
              </div>
              <div
                  onClick={() => (window.location.href = 'https://deerbook.cn')}
                  className='w-[80px] h-[35px] rounded-full bg-[#FFAA1B] flex items-center justify-center text-[#FFFFFF]'>
                下载App
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DowloadAppBanner;
