import { Toast } from 'antd-mobile'
import axios from 'axios'
import { isApp } from '../utils/EnvUtils'
import { JsBridge } from '../utils/JsBridge' // 从 index.tsx 导入 tokenPromise

const _host = 'https://api.deerbook.cn'
const _hostTest = 'https://testvq.deerbook.cn'
const _baseUrl = window.location.host === 'deerbook.cn' ? _host : _hostTest
// const _baseUrl = _host;
// const _baseUrl = "http://192.168.31.123:8080/";

export function getApiBaseHost(): string {
  return _baseUrl
}

export function getWebBaseHost(): string {
  return 'https://' + window.location.host
}

export const axiosInstance = axios.create({
  baseURL: _baseUrl,
})

// 添加请求拦截器来动态设置 token，并确保等待 token
axiosInstance.interceptors.request.use(
  async function (config) {
    const userAgent = navigator.userAgent
    const authMatch = userAgent.match(/Authorization_Bearer\s([^\s]+)/)
    if (authMatch && authMatch[1]) {
      const token = authMatch[1]
      config.headers.authorization = `Bearer ${token}`
    }
    //mock
    // config.headers.authorization =
    //   'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEwMTI2MTYsIm1pZCI6MTAxMjE2OCwiZXhwIjoxNzQxNzc3MjQzfQ.FJQmf24OlieF0h7cN-2H8e7AXVmgW1UcK5rm0s3ZAW0'
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const _nativeDecrypt = (data: string) => {
  return new Promise((resolve) => {
    JsBridge.nativeDecrypt(data, (args: string) => {
      console.log('args', args)
      resolve(args)
    })
  })
}

axiosInstance.interceptors.response.use(
  async function (response) {
    // 生产解密
    if (
      _baseUrl.startsWith(_host) &&
      response?.data?.data &&
      response?.data?.eType == 1
    ) {
      const _decryptData = await _nativeDecrypt(response?.data?.data)
      response.data.data = _decryptData
    }
    return response
  },
  function (error) {
    console.error(error.response?.status, window.location.href)
    if (error.response?.status === 401) {
      JsBridge.gotoLogin({ sourceFrom: 'h5' })
      return
    }
    if (error.response?.status === 400) {
      return error.response
    }
    return Promise.reject(error)
  }
)

/**
 * 发起 GET 基础请求，包含自己服务端约定格式数据的基本逻辑处理，第三方的请求不要调用该基础请求
 * @template T - 响应数据的类型
 * @param {string} url 请求的 URL
 * @param {Record<string, any>} params 请求的参数
 * @returns {Promise<{success: boolean, data?: T, msg?: string, code?: number}>} 返回一个包含结果的 Promise 对象
 */
export async function requestGet<T>(
  url: string,
  params: Record<string, any>
): Promise<{ success: boolean; data?: T; msg?: string; code?: number }> {
  try {
    const response = await axiosInstance.get(url, { params: params })

    if (response.status === 200 && response.data?.result === 'SUCCESS') {
      return { success: true, data: response.data.data }
    } else {
      return {
        success: false,
        msg: response.data?.msg ?? '请求失败',
        code: response.status,
      }
    }
  } catch (error) {
    return { success: false, msg: '请求失败', code: 500 }
  }
}

export async function requestPost<T>(
  url: string,
  data?: any,
  params?: Record<string, any>
): Promise<{ success: boolean; data?: T; msg?: string; code?: number }> {
  try {
    const response = await axiosInstance.post(url, data, { params: params })

    if (response.status === 200 && response.data?.result === 'SUCCESS') {
      return { success: true, data: response.data.data }
    } else {
      return {
        success: false,
        msg: response.data?.msg ?? '请求失败',
        code: response.status,
      }
    }
  } catch (error) {
    return { success: false, msg: '请求失败', code: 500 }
  }
}
