import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import BottomBtn from './BottomBtn';
import TrumpetIcon from '@/images/h5-common/trumpet.webp';
import Header from './Header';
import { getRecordedShareData } from '../../restful/recorded_share';
import {logInJsx, queryURLParams} from '../../utils/jsUtils';
import {Checkbox, ErrorBlock, Loading, Swiper, SwiperRef, Toast} from 'antd-mobile';
import WeChatMask from '../../components/h5/WeChatMask';

import PlayIcon from '@/images/h5-common/play_btn.webp';
import PauseIcon from '@/images/h5-common/pause.webp';
import {getDirectoryBasic} from "../../restful/self_service_api";
import {DirectoryBookModel, Page, Sentence} from "../../typings/directory_book_model";
import MyAudioPlayer, {AudioPlayerHandle} from "../../components/player/MyAudioPlayer";
import {AudioRecord, MediaItem, PlayDataSource} from "../../typings/play_data_source";
import DowloadAppBanner from "../../components/h5/DowloadAppBanner";
import ScoreStars from "../../components/picturebook/ScoreStars";
import {copyToClipboard} from "../../utils/common";

/**
 * @description 分享绘本录制的音频
 */
function ShareRecorded() {
  const urlParamObj = queryURLParams(window.location.href);
  const [directoryBookModel, setDirectoryBookModel] = useState<DirectoryBookModel>();
  const [playDataSourceList, setPlayDataSourceList] = useState<PlayDataSource[]>();
  const [playDataSourceRecordList, setPlayDataSourceRecordList] = useState<PlayDataSource[]>();
  const [loadingSate, setLoadingSate] = useState(0);//0加载中 1加载成功 2加载失败
  const rootSwiperRef = useRef<SwiperRef | null>(null);
  const [isShowWXShare, setIsShowWXShare] = useState(false); // 微信分享弹层
  const [playRecord, setPlayRecord] = useState<boolean>(true); // 是否只听原声
  const [autoPlayNext, setAutoPlayNext] = useState<boolean>(true); // 是否自动翻页播放
  const [recordPlayIndex, setRecordPlayIndex] = useState<number>(); // 当前用户音频播放位置
  const [isPlaying, setIsPlaying] = useState(false);
  const [curPageIndex, setCurPageIndex] = useState(0); // 整体绘本的位置
  const audioPlayerRef = useRef<AudioPlayerHandle>(null);
  const [hasPlay, setHasPlay] = useState(false);

  useEffect(() => {

    if(audioPlayerRef.current){
      audioPlayerRef.current.setPlayRecord(playRecord);
    }

  }, [playRecord]);

  useEffect(() => {

    if(audioPlayerRef.current && audioPlayerRef.current.getPlayData().playlist===playDataSourceList){
      audioPlayerRef.current.setAutoPlayNext(autoPlayNext);
    }

  }, [autoPlayNext]);

  useEffect(() => {
    if (urlParamObj?.title) {
      document.title = urlParamObj?.title;
    }

    // getMyAudio();

    if(urlParamObj?.r){
      // setIsDataChange(false);
      setLoadingSate(0);
      getRecordedShareData({ r: urlParamObj?.r }).then((res) => {
        if (res.success) {

          let tDirectoryBookModel = res.data;

          // console.log('getRecordedShareData data:', res.data);

          let tPlayDataSourceList:PlayDataSource[] = [];
          let tPlayDataSourceRecordList:PlayDataSource[] = [];
          // console.log("directoryBookModel?.items.length:",directoryBookModel?.items.length);
          tDirectoryBookModel?.items?.forEach((item:Page,index)=>{

            let audioRecords: AudioRecord[] = [];
            let mediaItems: MediaItem[] = [];
            // console.log("directoryBookModel item:",item,index);
            if(item.audioRecord){
              // console.log(`item.audioRecord 111:${item.audioRecord} ${item.audioRecord.mediaUrl}`);
              item.audioRecord.isSentence = false;
              item.audioRecord.directoryIndex = index;
              item.audioRecord.en = item.contentEn;
              item.audioRecord.cn = item.contentCn;
              audioRecords.push(item.audioRecord);
            }else if(item.sentences){
              item.sentences?.forEach((sentence:Sentence,sIndex)=>{
                if(sentence.audioRecord){
                  // console.log(`value.audioRecord 222:${sentence.audioRecord} ${sentence.audioRecord.mediaUrl}`);
                  sentence.audioRecord.isSentence = true;
                  sentence.audioRecord.directoryIndex = index;
                  sentence.audioRecord.sentenceIndex = sIndex;
                  sentence.audioRecord.en = sentence.en;
                  sentence.audioRecord.cn = sentence.cn;
                  audioRecords.push(sentence.audioRecord);
                }
              })
            }
            mediaItems.push({
              mediaUrl:item.mediaUrl,
              startSec:item.startSec,
              endSec:item.endSec,
              directoryIndex:index,
              isSentence:false,
            })
            let playDataSource:PlayDataSource={
              audioRecords:audioRecords,
              mediaList:mediaItems
            };
            tPlayDataSourceList.push(playDataSource);
            if(audioRecords.length>0){
              audioRecords.forEach((value,index)=>{
                tPlayDataSourceRecordList.push({
                  audioRecords:[value],
                  mediaList:mediaItems
                });
              })
            }
          });
          // console.log(`directoryBookModel?.items.length 1111:${tDirectoryBookModel?.items.length} playDataSourceList:${playDataSourceList?.length} playDataSourceRecordList:${playDataSourceRecordList?.length}`)
          if(tDirectoryBookModel?.items){
            tDirectoryBookModel.items.unshift(tDirectoryBookModel.items[0]);//插入开始页
            tDirectoryBookModel.items.push(tDirectoryBookModel.items[0]);//插入结尾页
          }
          setDirectoryBookModel(tDirectoryBookModel);
          setPlayDataSourceList(tPlayDataSourceList);
          setPlayDataSourceRecordList(tPlayDataSourceRecordList);
          // console.log(`tDirectoryBookModel?.items.length 2222:${tDirectoryBookModel?.items.length} playDataSourceList:${playDataSourceList?.length} playDataSourceRecordList:${playDataSourceRecordList?.length}`)
          // setIsDataChange(true);
          setLoadingSate(1);
        } else {
          setLoadingSate(2);
          Toast.show({
            content: res.msg,
          });
        }
      });
    }

  }, []);

  function isLastItem(){
    return curPageIndex+1==directoryBookModel?.items.length;
  }

  useEffect(() => {
    rootSwiperRef.current?.swipeTo(curPageIndex);
  }, [curPageIndex]);

  function getInviter() {
    return (
        <div className="flex flex-col pt-[15px]">
          <div className="flex flex-row items-center">
            <img
                className="w-[40px] h-[40px] rounded-full object-cover"
                src={directoryBookModel?.userInviter?.avatar}
                alt=""/>
            <div className="ml-[10px] text-[14px] font-medium">
              {directoryBookModel?.userInviter?.nickName}邀请你免费领奇鹿英语会员资格，一起学英语
            </div>
          </div>
          <div
              onClick={() =>
                  copyToClipboard(
                      `【免费领取英语学习神器】点击【https://deerbook.cn】下载安装后输入邀请码==>${directoryBookModel?.userInviter?.inviterCode}<==即可免费领取奇鹿Vip会员，快来领取吧！`
                  )
              }
              className="flex flex-row bg-[#F5F5F5] rounded-[13px] pl-[14px] pt-[14px] pr-[27px] pb-[14px] mt-[10px] items-center">
            <div className="flex-1 text-[#666666] text-[14px] font-normal">
              邀请码：{directoryBookModel?.userInviter?.inviterCode}
            </div>
            <div className="text-[#AB885C] text-[14px] font-semibold">
              复制
            </div>
          </div>
        </div>
    );
  }

  // console.log(`directoryBookModel:${directoryBookModel} playDataSourceList:${playDataSourceList?.length} playDataSourceRecordList:${playDataSourceRecordList?.length}`)

  if (loadingSate==0) {
    return (
      <div className='w-full h-[100vh] flex items-center justify-center'>
        <div className='fixed top-0 left-0 right-0'>
          <DowloadAppBanner alignBottom={false}/>
        </div>
        <Loading
          className='flex flex-col items-center justify-center'
        />
      </div>
    );
  }else if(loadingSate==2){
    return (
        <div className='w-full h-[100vh] flex items-center justify-center'>
          <div className='fixed top-0 left-0 right-0'>
            <DowloadAppBanner alignBottom={false}/>
          </div>
          <ErrorBlock
              status='default'
              className='flex flex-col items-center justify-center'
          />
        </div>
    );
  }

  return (
      <div className='flex items-center justify-center'>
        <div className='max-w-[800px] w-full'>
          <MyAudioPlayer
              ref={audioPlayerRef}
              // playNextInterval={500}
              onPlayPause={
                (isPlay) => {
                  setHasPlay(true);
                  setIsPlaying(isPlay);
                  // console.log(`MyAudioPlayer isPlay:${isPlay}`);
                }
              }
              onPlayNext={
                (index) => {
                  // console.log(`onPlayNext index:${index} curPageIndex:${curPageIndex}`);
                  if(audioPlayerRef.current?.getPlayData().playlist===playDataSourceList) {
                    setCurPageIndex(index + 1);
                  }
                  // if(curPageIndex!=index+1){
                  //   rootSwiperRef.current?.swipeTo(index+1);
                  // }
                }
              }
              onListPlayEnd={
                (index) => {
                  // console.log(`onListPlayEnd index:${index} curPageIndex:${curPageIndex}`);
                  if(directoryBookModel && audioPlayerRef.current?.getPlayData().playlist===playDataSourceList) {
                    setCurPageIndex(directoryBookModel.items.length - 1);
                  }
                  // if(curPageIndex!=index+1){
                  //   rootSwiperRef.current?.swipeTo(index+1);
                  // }
                }
              }
          />
          <Swiper
              indicator={() => null}
              defaultIndex={curPageIndex}
              //如果没有播放过，第一页禁止左滑，这样能保证，用户点击一次物理播放，确保后续可以通过程序控制播放
              allowTouchMove={(curPageIndex == 0 && !hasPlay) ? false : true}
              onIndexChange={(index) => {
                setCurPageIndex(index);
                if(!audioPlayerRef.current){
                  return;
                }
                // console.log(`index:${index} playDataSourceList!.length:${playDataSourceList!.length}`);
                // console.log(`audioPlayerRef.current?.getPlayData()===playDataSourceList:${audioPlayerRef.current.getPlayData().playlist===playDataSourceList}`);
                if (playDataSourceList && (index - 1) >= 0 && (index - 1) < playDataSourceList!.length) {
                  if(audioPlayerRef.current.getPlayData().playlist===playDataSourceList){
                    audioPlayerRef.current.playIndex(index-1);
                  }else {
                    audioPlayerRef.current.setDataSource({
                      playlist: playDataSourceList,
                      index: index - 1,
                      sentenceIndex: 0,
                      playRecord: playRecord,
                      autoPlay: true,
                      autoPlayNext: autoPlayNext
                    });
                  }
                } else {
                  audioPlayerRef.current?.pause();
                  audioPlayerRef.current.setDataSource({
                    playlist:[],
                    index:0,
                    sentenceIndex:0,
                    playRecord:true,
                    autoPlay:false,
                    autoPlayNext:true,
                  });
                }

              }}
              ref={rootSwiperRef}>

            {directoryBookModel?.items?.map((item: any, index: number) => {

              if (index == 0) {
                return <Swiper.Item key={'first'}>
                  <div className='h-[100vh]'>
                    <div className='w-full fixed top-0'>
                      {/* <Header title={urlParamObj?.title} /> */}
                      <DowloadAppBanner alignBottom={false}/>
                    </div>
                    <div className='w-full h-[80px]'></div>
                    <div className='pt-[24px] h-[calc(100%_-_104px)]'>
                      <div className='flex items-center px-[16px] mb-[24px]'>
                        <img
                            className='w-[100px] h-[120px] rounded-[12px] mr-[24px]'
                            src={directoryBookModel?.imageUrl}
                            alt=''
                        />
                        <div className='mb-[24px]'>
                          <div className='text-[#333333] text-[18px] font-semibold mb-[10px]'>
                            {directoryBookModel?.title}
                          </div>
                          <div className='flex mb-[14px]'>
                            {directoryBookModel?.tags?.map((item: string, index: number) => {
                              return (
                                  <div
                                      key={index}
                                      className='px-[8px] py-[2px] text-[12px] text-[#999999] bg-[#E6E6E6] rounded-full mr-[10px]'
                                  >
                                    {item}
                                  </div>
                              );
                            })}
                          </div>
                          <div className='line-clamp-2 w-full text-[14px]'>
                            {directoryBookModel?.intro}
                          </div>
                        </div>
                      </div>
                      <div className='w-full px-[16px] overflow-y-auto pb-[120px] h-[calc(100%_-_120px)]'>
                        {/*{*/}
                        {/*  logInJsx("playDataSourceRecordList 1:", playDataSourceRecordList?.length)*/}
                        {/*}*/}
                        {playDataSourceRecordList?.map((item: PlayDataSource, index) => (
                            <div
                                key={index}
                                // onClick={() => playUserAudio(index)}
                                onClick={() => {
                                  setRecordPlayIndex(index);
                                  if (audioPlayerRef.current) {
                                    audioPlayerRef.current.setDataSource({
                                      playlist: [playDataSourceRecordList![index]],
                                      index: 0,
                                      sentenceIndex: 0,
                                      playRecord: true,
                                      autoPlay: true,
                                      autoPlayNext: false
                                    });
                                  }
                                }}
                                className={`flex flex-row px-[16px] py-[18px] mb-[14px] rounded-[14px] ${
                                    index === recordPlayIndex ? 'bg-[#99d7f1]' : 'bg-[#E7F8FF]'
                                } text-[16px] font-semibold`}
                            >
                              <div className="flex-1">
                                <span>
                                {item.audioRecords[0].en}
                              </span>
                                <img
                                    className='w-[18px] h-[14px] inline ml-[4px]'
                                    src={TrumpetIcon}
                                    alt=''
                                />
                              </div>
                              <ScoreStars
                                  score={item.audioRecords[0].suggestedScore}
                                  className="justify-end"
                              />
                            </div>
                        ))}
                        {getInviter()}
                      </div>
                    </div>
                    <BottomBtn
                        onClick={() => {
                          // 切换到绘本，停止原声播放
                          // if (currentUserAudio) {
                          //   // pauseAudio();
                          //   currentUserAudio.currentTime = 0; // 重置音频位置
                          // }
                          rootSwiperRef.current?.swipeTo(1);
                        }}
                        title={urlParamObj?.title}
                    />
                  </div>
                </Swiper.Item>;
              } else if (index + 1 == directoryBookModel?.items.length) {
                return <Swiper.Item key={'last'}>
                  <div className='pt-[60px]'>
                    {/*<div className='fixed top-0 z-20 w-[100vw]'>*/}
                    {/*  <Header*/}
                    {/*      isHiddenBox={true}*/}
                    {/*      showShare={() => setIsShowWXShare(true)}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className='relative mx-[24px] h-[100px] flex items-end'>
                      <img
                          className='w-[78px] h-[84px] rounded-[8px] absolute left-0 bottom-0 z-10'
                          src={directoryBookModel?.imageUrl}
                          alt=''
                      />
                      <div className='w-[78px] h-[90px] rounded-[8px] absolute left-[6px] bottom-0 bg-[#D3EBEB]'></div>
                      <div
                          className='w-full border-4 border-[#EEF9FA] border-solid rounded-[18px] h-[74px] flex items-center justify-center'>
                        <div className='w-[90px]'></div>
                        <div className='text-[18px] text-[#1E1C1C]'>{`${urlParamObj?.title}欣赏结束`}</div>
                      </div>
                    </div>

                    <div className="pl-[15px] pr-[15px] pt-[25px]">
                      {getInviter()}
                    </div>

                    <div className="flex flex-row mt-[40px] items-center justify-center w-full space-x-[20px]">
                      <div
                          onClick={() => rootSwiperRef.current?.swipeTo(0)}
                          className='w-[148px] py-[12px] px-[16px] rounded-full flex items-center justify-center bg-[#40D8F4] text-[#FFFFFF] text-[16px] shadow-md'>
                        返回首页
                      </div>
                      <div
                          onClick={() => setIsShowWXShare(true)}
                          className='w-[148px] py-[12px] px-[16px] rounded-full flex items-center justify-center bg-[#FFAA1B] text-[#FFFFFF] text-[16px] shadow-md'>
                        分享
                      </div>
                    </div>

                  </div>
                </Swiper.Item>;
              }

              return (
                  <Swiper.Item key={index}>
                    <div className='flex flex-col h-screen pb-[80px] overflow-y-auto'>
                      <div className='w-full justify-center items-center'>
                        <img
                            src={item.imageUrl}
                            alt=''
                            className='object-contain object-center w-full max-h-[calc(100vh-80px-150px)]'
                        />
                      </div>
                      <div className='flex w-full p-[10px]'>
                        <div className='flex-1'>
                          <div className='text-[24px] font-bold'>
                            {item?.contentEn}
                          </div>
                        </div>
                        <div className='flex flex-col items-center justify-start'>
                          <img
                              className='w-[60px] h-[60px]'
                              src={isPlaying ? PauseIcon : PlayIcon}
                              alt=''
                              onClick={() => {
                                audioPlayerRef.current?.playPause();
                              }}
                          />
                          <span className='text-[14px] mt-[8px]'>
                            {index}/{directoryBookModel.items.length - 2}
                          </span>
                        </div>

                      </div>
                    </div>
                  </Swiper.Item>
              );
            })}

          </Swiper>

          <div className={`fixed top-0 w-full max-w-[800px] ${curPageIndex > 0 ? '' : 'hidden'}`}>
            <Header
                isHiddenBox={isLastItem()?true:false}
                title={isLastItem()?urlParamObj?.title:""}
                setPlayRecord={setPlayRecord}
                autoPlayNext={autoPlayNext}
                setAutoPlayNext={setAutoPlayNext}
                showShare={() => setIsShowWXShare(true)}
            />
          </div>

          {curPageIndex > 0 ? (
              <DowloadAppBanner></DowloadAppBanner>
          ) : null}

          <WeChatMask
              isVisible={isShowWXShare}
              closeMask={() => setIsShowWXShare(false)}
              maskType={1}
          />
        </div>
      </div>
  );
}

export default ShareRecorded;
