export function isWechat() {
  if (typeof window !== 'undefined') {
    return /MicroMessenger/i.test(window.navigator.userAgent);
  }
  return false;
}

export function isApp() {
  const regex = /com\.luqifei\.deerbook\b/;
  if (typeof window !== 'undefined') {
    return regex.test(navigator.userAgent);
  }
  return false;
}

export function compareVersion(version1: string, version2: string): boolean {
  const v1Parts = version1.split('.').map(Number);
  const v2Parts = version2.split('.').map(Number);

  const length = Math.max(v1Parts.length, v2Parts.length);

  for (let i = 0; i < length; i++) {
    const v1 = v1Parts[i] || 0; // 若不存在则用 0 代替
    const v2 = v2Parts[i] || 0; 

    if (v1 > v2) {
      return true;
    } else if (v1 < v2) {
      return false;
    }
  }

  return true; // 相等时返回 true
}