

const score_three_start = 80; // 例如，90分及以上得3星
const score_two_start = 60;   // 例如，75分到89分得2星
const score_one_start = 30;   // 例如，60分到74分得1星

export function getRecordStarCount(curRecordScore:number):number {
    if (curRecordScore >= score_three_start) {
        return 3;
    } else if (curRecordScore >= score_two_start) {
        return 2;
    } else if (curRecordScore >= score_one_start) {
        return 1;
    } else {
        return 0;
    }
}