export function queryURLParams(location: string) {
  let obj: Record<string, any> = {}
  location.replace(
    /([^?=&#]+)=([^?=&#]+)/g,
    (_, key, value) => (obj[key] = decodeURIComponent(value))
  )
  return obj
}

export const dealPhone = (text:string) =>{
  if(!text) return text
  if(text.length < 11) return text

  return text.slice(-11).replace(/^(.{3})(.{4})(.*)$/, '$1****$3')
}

export const logInJsx = (message?: any, ...optionalParams: any[]):string =>{
  console.log(message,optionalParams);
  return "";
}

