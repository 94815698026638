import { Toast } from 'antd-mobile';

export const JsBridge = {

  // 获取用户信息
  isInApp: ():boolean => {
    return messageMethodIsSupport('getUserInfo');
  },

  setNavigationBar: (data?: any) => {
    if (messageMethodIsSupport('setNavigationBar')) {
      window.setNavigationBar?.postMessage(data);
    }
  },

  openAlbumAndUpload: (data?: any) => {
    if (messageMethodIsSupport('openAlbumAndUpload')) {
      window.openAlbumAndUpload?.postMessage(data);
    }
  },
  // 打开文件选择
  openFileAndUpload: (data?: any) => {
    if (messageMethodIsSupport('openFileAndUpload')) {
      window.openFileAndUpload?.postMessage(data);
    }
  },

  // 获取用户信息
  getUserInfo: (data?: any) => {
    if (messageMethodIsSupport('getUserInfo')) {
      window.getUserInfo?.postMessage(data);
    }
  },

  // 跳转到登录页面
  gotoLogin: (data?: any) => {
    if (messageMethodIsSupport('gotoLogin')) {
      window.gotoLogin?.postMessage(data);
    }
  },

  // 关闭webview
  closeWebview: (data?: any) => {
    if (messageMethodIsSupport('closeWebview')) {
      window.closeWebview?.postMessage(data);
    }
  },

  // 设置全局函数，用户手势或者物理按键回退后的回调
  setClickBackCallback: (fun: any) => {
    window['clickBack'] = () => {
      try {
        fun();
      } catch (error) {
        return false;
      }
      return true;
    };
  },

  // native 解密接口返参
  nativeDecrypt: (data: string, callback: (args: string) => void) => {
    function getDecryptData(decryptData: string) {
      console.log('decryptData===', decryptData);
      callback(decryptData);
      return decryptData;
    }

    window.getDecryptData = getDecryptData;
    if (messageMethodIsSupport('nativeDecrypt')) {
      window.nativeDecrypt?.postMessage(data);
    } else {
      Toast.show({
        content: '请升级APP到最新版本',
      });
    }
  },

  shareUrl: (data?: any) => {
    if (messageMethodIsSupport('shareUrl')) {
      window.shareUrl?.postMessage(data);
    }
  },

  // 唤起APP分享
  shareWeb: (data?: any) => {
    if (messageMethodIsSupport('shareWeb')) {
      window.shareWeb?.postMessage(data);
    }
  },

  // APP内唤起微信客服
  openCustomerServiceChat: (data?: any) => {
    if (messageMethodIsSupport('openCustomerServiceChat')) {
      window.openCustomerServiceChat?.postMessage(data);
    }
  },

  //会员下单
  buyVip: (data?: any) => {
    if (messageMethodIsSupport('buyVip')) {
      window.buyVip?.postMessage(data);
    }
  },
  //app升级
  updateAppVersion: (data?: any) => {
    if (messageMethodIsSupport('updateAppVersion')) {
      window.updateAppVersion?.postMessage(data);
    }
  },
  
  openWebPage: (data?: any) => {
    if (messageMethodIsSupport('openWebPage')) {
      window.openWebPage?.postMessage(data);
    }
  },

  sharePageFreeMembership: (data: string) => {
    if (messageMethodIsSupport('shareUrl')) {
      window.shareUrl?.postMessage(data)
    }
  },

  hideClientNavbar: (data: string) => {
    if (messageMethodIsSupport('setNavigationBar')) {
      window.setNavigationBar?.postMessage(data)
    }
  },

  wxLogin: (data: any) => {
    if (messageMethodIsSupport('wxLogin')) {
      window.wxLogin?.postMessage(data)
    }
  },
  
};

// message 类的方法，直接挂在widow上, 只能调用APP方法，无法获取返回值
export function messageMethodIsSupport(methodName: string) {
  return typeof window !== 'undefined' && window?.[methodName as keyof Window];
}
