import {axiosInstance, requestGet} from './request';
import {DirectoryBasicModel} from "../typings/directory_basic_model";
import {DirectoryBookModel} from "../typings/directory_book_model";

// 获取录音分享数据
// export function getRecordedShareData(r: string) {
//   return axiosInstance.get('/v1/share/uar?r=' + r);
// }

export function getRecordedShareData(
  {r}: Record<string, any>
):Promise<{success: boolean, data?: DirectoryBookModel, msg?: string, code?: number}> {

  const params = {
    r: r,
  };

  return requestGet<DirectoryBookModel>('/v1/share/uar', params);
}