import React, { Suspense, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { initRem } from './rem'
import { isMobile } from './utils/isMobile'
import ShareRecorded from './pages/share_recorded'
import AboutApp from './pages/about_app'
import PriceUp from './pages/price_up'

const H5Website = React.lazy(() => import('./pages/h5_website'))
const Website = React.lazy(() => import('./pages/website'))
const H5SelfService = React.lazy(() => import('./pages/h5_self_service'))
const VipSale = React.lazy(() => import('./pages/h5_vip_sale'))
const BaiduPan = React.lazy(() => import('./pages/h5_self_service_baidu'))
const ShareCourse = React.lazy(() => import('./pages/share_course'))
const ShareCourseOutside = React.lazy(
  () => import('./pages/share_course_outside')
)
const VipShare = React.lazy(() => import('./pages/vip_share'))
const CumulativeInvitation = React.lazy(
  () => import('./pages/cumulative_invitation')
)
const ReceiveReward = React.lazy(() => import('./pages/receive_reward'))
const AccountDetails = React.lazy(() => import('./pages/account_details'))
const ActivityDescription = React.lazy(
  () => import('./pages/activity_description')
)
const VipShareActivity = React.lazy(() => import('./pages/vip_share_activity'))
const GreatTracher = React.lazy(() => import('./pages/great_teacher'))
const FormTeacher = React.lazy(() => import('./pages/form_teacher'))

const router = createBrowserRouter([
  {
    path: '/',
    element: isMobile() ? <H5Website /> : <Website />,
  },
  {
    path: '/wechat',
    element: isMobile() ? <H5Website /> : <Website />,
  },
  {
    path: '/app',
    element: isMobile() ? <H5Website /> : <Website />,
  },
  {
    path: '/self_service',
    element: <H5SelfService />,
  },
  {
    path: '/self_service_baidu',
    element: <BaiduPan />,
  },
  {
    path: '/share_course',
    element: <ShareCourse />,
  },
  {
    path: '/share_course_outside',
    element: <ShareCourseOutside />,
  },
  {
    path: '/vip_sale',
    element: <VipSale />,
  },
  {
    path: '/vip_share',
    element: <VipShare />,
  },
  {
    path: '/vip_share_activity',
    element: <VipShareActivity />,
  },
  {
    path: '/cumulative_invitation',
    element: <CumulativeInvitation />,
  },
  {
    path: '/receive_reward',
    element: <ReceiveReward />,
  },
  {
    path: '/account_details',
    element: <AccountDetails />,
  },
  {
    path: '/activity_description',
    element: <ActivityDescription />,
  },
  {
    path: '/recorded_share',
    element: <ShareRecorded />,
  },
  {
    path: '/about_app',
    element: <AboutApp />,
  },
  {
    path: '/great_teacher',
    element: <GreatTracher />,
  },
  {
    path: '/price_up',
    element: <PriceUp />,
  },
  {
    path: '/p',
    element: <PriceUp />,
  },
  {
    path: '/form_teacher',
    element: <FormTeacher />,
  },
])

const App = () => {
  useEffect(() => {
    // 注册全局消息监听器
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.type === 'setUserInfo') {
        console.log('setUserInfo123:', event.data.token)
        localStorage.setItem('token', event.data.toke)
      }
    }
    window.addEventListener('message', handleMessage)
    // // 在应用初始化时调用Flutter的获取用户信息的接口
    // JsBridge.getUserInfo({ sourceFrom: "h5" });
    // // 清理事件监听器
    // return () => {
    //   window.removeEventListener('message', handleMessage);
    // };
  }, [])

  return (
    <React.StrictMode>
      <Suspense fallback={<div />}>
        <RouterProvider router={router} />
      </Suspense>
    </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

//不要等比缩放
// initRem();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
