import ShareIcon from '@/images/h5-common/icon_share.webp';
import { Checkbox } from 'antd-mobile';

interface IProps {
    title?: string;
    isHiddenBox?: boolean;
    showShare?: () => void;
    setPlayRecord?: (val: boolean) => void;
    autoPlayNext?:boolean;
    setAutoPlayNext?: (val: boolean) => void;
}

function Header({ title, isHiddenBox, showShare, setPlayRecord,autoPlayNext=true,setAutoPlayNext}: IProps) {
  return (
    <div className='w-full px-[16px] py-[16px] flex items-center justify-between'>
      {isHiddenBox ? <div className='w-[40px]'/> : (
          <div className='flex items-start'>
              <div
                  className='bg-[#000000] rounded-full h-[30px] text-[#ffffff] bg-opacity-65 font-semibold px-[14px] flex items-center justify-center mr-[10px]'>
                  <Checkbox
                      checked={autoPlayNext}
                      onChange={(checked) => {
                          setAutoPlayNext && setAutoPlayNext(checked);
                      }}
                      style={{
                          '--icon-size': '18px',
                      }}
                  >
                      自动翻页
                  </Checkbox>
              </div>
              <div
                  className='bg-[#000000] rounded-full h-[30px] text-[#ffffff] bg-opacity-65 font-semibold px-[14px] flex items-center justify-center mr-[10px]'>
                  <Checkbox
                      onChange={(checked) => {
                          setPlayRecord && setPlayRecord(!checked);
                      }}
                      style={{
                          '--icon-size': '18px',
                          borderColor: '#ff6b00', // 未选中状态的边框颜色
                          // backgroundColor: '#ff6b00',   // 选中状态的背景颜色
                      }}
                  >
                      原声
                  </Checkbox>
              </div>
          </div>
      )}
        <div className='text-[16px] font-semibold truncate px-[8px] overflow-hidden text-ellipsis whitespace-nowrap'>{title}</div>
        <div className=''>
            <img
                className='w-[40px] h-[40px]'
                src={ShareIcon}
                onClick={showShare}
                alt=''
            />
        </div>
    </div>
  );
}

export default Header;
