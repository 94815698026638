interface IProps {
  title: string;
  onClick: () => void;
}

function BottomBtn({ title, onClick }: IProps) {
  return (
    <div
      onClick={onClick}
      style={{
        background:
          'linear-gradient(178.17deg, rgba(255, 255, 255, 0) 16.3%, #FFFFFF 72.27%)',
      }}
      className='flex items-center justify-center fixed bottom-0 w-full h-[120px] bg-[linear-gradient(178.17deg, rgba(255, 255, 255, 0) 16.3%, #FFFFFF 72.27%)]'
    >
      <div className='min-w-[255px] py-[12px] px-[16px] mx-[16px] rounded-full flex items-center justify-center bg-[#FFAA1B] text-[#FFFFFF] text-[16px]'>
        {title ? `开始欣赏${title}` : '开始欣赏录音作品'}
      </div>
    </div>
  );
}

export default BottomBtn;
