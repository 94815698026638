import MaskImg0 from '@/images/h5-common/wechat_mask_0.webp';
import MaskImg1 from '@/images/h5-common/wechat_mask_1.webp';

function getMaskImg(maskType: number) {
  if (maskType == 0) {
    return MaskImg0;
  } else if (maskType == 1) {
    return MaskImg1;
  } else {
    return MaskImg0;
  }
}

function WeChatMask({ isVisible, closeMask, maskType }: Record<string, any>) {
  return isVisible ? (
    <div
      onClick={() => closeMask()}
      className='fixed top-0 bottom-0 right-0 left-0 bg-[#333333] bg-opacity-75 z-[1000]'
    >
      <div className='flex flex-row w-full h-full justify-end pr-[10px]'>
        <div className='max-w-[300px]'>
          <img src={getMaskImg(maskType)} alt='' />
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}

export default WeChatMask;
