import {getRecordStarCount} from "../../utils/PictureBookUtils";
import StarGold from '@/images/picture_book/star_gold.webp';
import StarGrey from '@/images/picture_book/star_grey.webp';

interface ScoreStarsProps {
    score: number;
    className?: String;
}


function ScoreStars({score,className}:ScoreStarsProps) {
    const goldStars = getRecordStarCount(score);
    const grayStars = 3 - goldStars;

    return (
        <div className={`${className??""} flex flex-row`}>
            {/* 显示金星 */}
            {[...Array(goldStars)].map((_, index) => (
                <img key={index} src={StarGold} alt="gold-star"
                     style={{width: '20px', height: '20px', marginRight: '5px'}}/>
            ))}
            {/* 显示灰星 */}
            {[...Array(grayStars)].map((_, index) => (
                <img key={`gray-${index}`} src={StarGrey} alt="gray-star"
                     style={{width: '20px', height: '20px', marginRight: '5px'}}/>
            ))}
        </div>
    );
}

export default ScoreStars;